import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import pic2 from '../assets/pic2.jfif';
import ReactPlayer from 'react-player';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Video extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">HOW TO MINT YOUR NFT</div>

                    <div class="vidCont1">
						<ReactPlayer
							width='900px'
							height='500px'
							controls
							url='https://www.youtube.com/watch?v=9PJQ2shCZhI'
							onReady={() => console.log('onReady callback')}
							onStart={() => console.log('onStart callback')}
							onPause={() => console.log('onPause callback')}
							onEnded={() => console.log('onEnded callback')}
							onError={() => console.log('onError callback')}
						/>
					</div>

					<div class="vidCont6">
						<ReactPlayer
							width='1280px'
							height='720px'
							controls
							url='https://www.youtube.com/watch?v=9PJQ2shCZhI'
							onReady={() => console.log('onReady callback')}
							onStart={() => console.log('onStart callback')}
							onPause={() => console.log('onPause callback')}
							onEnded={() => console.log('onEnded callback')}
							onError={() => console.log('onError callback')}
						/>
					</div>

					<div class="vidCont2">
						<ReactPlayer
							width='900px'
							height='500px'
							controls
							url='https://www.youtube.com/watch?v=9PJQ2shCZhI'
							onReady={() => console.log('onReady callback')}
							onStart={() => console.log('onStart callback')}
							onPause={() => console.log('onPause callback')}
							onEnded={() => console.log('onEnded callback')}
							onError={() => console.log('onError callback')}
						/>
					</div>

					<div class="vidCont3">
						<ReactPlayer
							width='700px'
							height='400px'
							controls
							url='https://www.youtube.com/watch?v=9PJQ2shCZhI'
							onReady={() => console.log('onReady callback')}
							onStart={() => console.log('onStart callback')}
							onPause={() => console.log('onPause callback')}
							onEnded={() => console.log('onEnded callback')}
							onError={() => console.log('onError callback')}
						/>
					</div>

					<div class="vidCont4">
						<ReactPlayer
							width='450px'
							height='250px'
							controls
							url='https://www.youtube.com/watch?v=9PJQ2shCZhI'
							onReady={() => console.log('onReady callback')}
							onStart={() => console.log('onStart callback')}
							onPause={() => console.log('onPause callback')}
							onEnded={() => console.log('onEnded callback')}
							onError={() => console.log('onError callback')}
						/>
					</div>

					<div class="vidCont5">
						<ReactPlayer
							width='300px'
							height='200px'
							controls
							url='https://www.youtube.com/watch?v=9PJQ2shCZhI'
							onReady={() => console.log('onReady callback')}
							onStart={() => console.log('onStart callback')}
							onPause={() => console.log('onPause callback')}
							onEnded={() => console.log('onEnded callback')}
							onError={() => console.log('onError callback')}
						/>
					</div>
                </div>
            </div>
        )
    }
}

export default Video;


import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import pic2 from '../assets/pic2.jfif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Benefits extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">BENEFITS</div>

                    <div class="about2">

                        <div class="aboutP2">
                            <p>- The Black Card exclusive membership offers various benefits, including access to the Discord community to connect and network with fellow members</p>
                            <p>- Exclusive entry to members-only events</p>
                            <p>- Discounts on tickets for select curated Outlet LA events or hosted/sponsored events</p>
                            <p>- NFT holders can also look forward to additional exclusive perks and being the first to receive event announcements</p>
                            <p>- Members can skip the lines at The Outlet LA hosted/sponsored events</p>
                            <p>- A complimentary glass of champagne</p>
                            <p>- Provide feedback on future event ideas</p>
                            <p>- Gain insight into premier events in LA</p>
                            <p>- As an exclusive Black Card member, you will receive discounted rates when renting any of our Outlet event spaces for your personal social gatherings.</p>
                         </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Benefits;


import './App.css';
import { useState } from 'react';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import { CrossmintNFTCollectionView } from "@crossmint/client-sdk-react-ui";
import logo from './assets/logo.png';
import Story from './Componets/story';
import Footer from './Componets/footer';
import os from './assets/os.png';
import os2 from './assets/opensea2.png';
import $ from 'jquery';
import up from './assets/flash.png';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import video from './assets/003.m4v';
import Extras from './Componets/extras';
import Creator from './Componets/creator';
import Benefits from './Componets/benefits';
import Terms from './Componets/terms';
import FAQ from './Componets/faq';
import img1 from './assets/pic4.jpg';
import img2 from './assets/pic4--.jpg';
import img3 from './assets/pic5.jpg';
import img5 from './assets/pic7.jpg';
import img6 from './assets/pic8.jpg';
import img7 from './assets/pic9.jpg';
import img8 from './assets/pic10.jpeg';
import img9 from './assets/pic4-.jpg';
import tutorial from './assets/The Outlet LA NFT how to vid.mp4';
import thumbnail from './assets/thubmnail.jpg';
import BigNumber from 'bignumber.js';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import Video from './Componets/video';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;
let maticPrice;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

/*
$(".container").hover(
	function () {
		$(this).find(".text").show();
	},
	function () {
		$(this).find(".text").hide();
	}
);
*/

let fullPrice;
let price;

const getFullPrice = () => {
	/*	$(function () {
			//$.get('https://api.binance.com/api/v3/ticker/price?symbol=MATICUSDT', function (data) {
			$.get('https://api-un9.pages.dev/', function (data) {
	
				price = data.price;
				fullPrice = 999.22 / (price);
				console.log("price :" + price);
				console.log("fullPrice : " + fullPrice);
			});
		});
	*/

	const maticPriceDiv = document.getElementById("maticPrice");

	// Fetch data from CoinGecko API
	fetch("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en")
		.then(response => response.json())
		.then(data => {
			// Extract the price from the response
			maticPrice = data[0].current_price;

			// Display the price in the div
			//maticPriceDiv.textContent = `Matic Price: $${maticPrice}`;
			console.log("maticPrice : " + maticPrice * 100.15);


			fullPrice = 999.22 / (maticPrice);
			console.log("price :" + maticPrice);
			console.log("fullPrice : " + fullPrice);



		})
		.catch(error => {
			console.error("Error fetching data:", error);
			//maticPriceDiv.textContent = "Error fetching data";
		});

	/*fetch('https://api-un9.pages.dev/')
	.then(response => response.text())
	.then(data => {
	  // Process the data here
	  console.log("textData:" + data); // Output the body data to the console
	})
	.catch(error => {
	  // Handle any errors
	  console.error('Error:', error);
	});*/




}

const opensea = () => {
	window.open("https://opensea.io/collection/the-outlet-la-nfts");
}

const home = () => {
	window.open("https://theoutletla.com/");
}

const tab2 = () => {
	window.open("https://theoutletla.com/private-eventplanning");
}

const tab3 = () => {
	window.open("#");
}

$(document).ready(function () {
	const stickyButton = $("#sticky-button");
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 200) {
			stickyButton.css("visibility", "visible");
		} else {
			stickyButton.css("visibility", "hidden");
		}
	});
});


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let public_sale_cost;
let MAX_PER_WALLET = 20;
let public_mint_status = false;
let wlCost;
let total_wl_limit;
let total_wl_count;
let wlMsg = "Whitelist Mint";
let FinalResult;
let videoBtn = 0;
let publicSaleCostInUSD = 99922;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "_priceFeedAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_deployer",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "ethToUsd",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLatestPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			}
		],
		"name": "owner_mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCostInUSD",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCostInUSD",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCostInUSD",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_slippagePercentage",
				"type": "uint256"
			}
		],
		"name": "setSlippagePercentage",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_priceFeedAddress",
				"type": "address"
			}
		],
		"name": "set_priceFeed",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "slippagePercentage",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "usdToEth",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
]

const address = "0x414E6c44e78456dA9E85E3853bA1Df8c6734Db28";
//const address = "0xD9d43D33827dd2731664986E9c94Edf368A4C859";
//const address = "0xACc8f61e3d3e076F0643E189f522Aedd06824619";
//const address = "0x4aD29DbbA7dfAAba4107a3045B0FC848F687cC17";
//const address = "0x7fF69cEbBcc8a63298c0D90117911F63A829a7A7, 0x3a364F7a051d6C1c558BAE87316f1dA1f0D7be0B";
let contract;

const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
//const clientID = "73bdd708-a964-4fbe-b3f8-4235356238d5";

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		nftMintingAmount: '1',
		totalValue: "",
		maxmint: '',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: 20,
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		_wlMsg: 'Whitelist Mint',
		_FinalResult: '',
		_navbarOpen: 0,
		_mintAmount_: '3',
		_totalPrice_: '0.001',
		_mintConfig_: '',
		_videoBtn: 0,
		_price: '',
		_publicSaleCostInUSD: 99922,
		_fullPrice: 1
	}

	async componentDidMount() {

		console.log("_mintAmount_ :" + this.state._mintAmount_);
		console.log("_totalPrice_ :" + this.state._totalPrice_);
		getFullPrice();
		let totalV = (this.state.totalValue / 1000000000000000000).toFixed(3);

		await new Promise(resolve => setTimeout(resolve, 1000));
		this.setState({ _fullPrice: fullPrice });
		let _VarmintConfig_ = '{"type":"erc-721","totalPrice":"' + this.state._fullPrice + '","_mintAmount": "1" }'

		this.setState({ _mintConfig_: _VarmintConfig_ });
		await new Promise(resolve => setTimeout(resolve, 250));
		console.log("_mintConfig_ :" + this.state._mintConfig_);

		/*await new Promise(resolve => setTimeout(resolve, 250));
		let maticDollarPrice = 900 * price; //this.state._cost * price;
		this.setState({ _price: maticDollarPrice });
		console.log("_price :" + this.state._price);*/
  		this.setState({ _price: fullPrice });
		console.log("_price :" + this.state._price);
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			try {
				//const number = new BigNumber(valueOfNFTs);

				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);
				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.mint(account, 1 * 1).send({ gasLimit: 385000, from: account, value: public_sale_cost });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + mintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);


			}

		} catch (err) {

			console.log(err);

		}
	}

	transferOwnership = async event => {
		event.preventDefault();

		const gasPrice = 178400000000;
		const gasLimit = 385000; // adjust the gas limit as needed

		await contract.methods.transferOwnership('0x00Ba64b42BE11c2eca9F9545F0600ccC18bF8cFB').send({
			from: account,
			gasPrice,
			gasLimit
		});
	}

	walletConnect = async event => {
		event.preventDefault();
		await new Promise(resolve => setTimeout(resolve, 2000));
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					rpc: {
						1: 'https://mainnet.infura.io/v3/811915bee3744bd38afcf17ecac0f9a6' // replace with your desired Ethereum network RPC endpoint
					}
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					rpc: {
						1: 'https://mainnet.infura.io/v3/811915bee3744bd38afcf17ecac0f9a6' // replace with your desired Ethereum network RPC endpoint
					},
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		//const web3 = new Web3('https://special-lively-leaf.matic.discover.quiknode.pro/cda16d3a6b1ff2d811cf74de901f16f2474f43b1/');
		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);

		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				//	(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					const chainId = 137;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Polygon Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://polygon-rpc.com/'],
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						publicSaleCostInUSD = await contract.methods.publicSaleCostInUSD().call();
						this.setState({ _publicSaleCostInUSD: publicSaleCostInUSD });

						public_sale_cost = await contract.methods.usdToEth(publicSaleCostInUSD).call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						mintAmount = 1;

						if (totalSupplyNFT == 20) {

							onlyLeft = 20 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							//valueOfNFTs = (mintAmount * this.state._cost);
							valueOfNFTs = Math.floor(mintAmount * this.state._cost);

							console.log("mintAmount :" + mintAmount);
							console.log("_cost :" + this.state._cost);
							this.setState({ nftMintingAmount: mintAmount });
							this.setState({ totalValue: valueOfNFTs });
							await new Promise(resolve => setTimeout(resolve, 250));

							let totalV = (this.state.totalValue / 1000000000000000000).toFixed(3);

							/*let _VarmintConfig_ = '{"type":"erc-721","totalPrice":' + fullPrice + ',"_mintAmount": "1" }'
							this.setState({ _mintConfig_: _VarmintConfig_ });*/
							await new Promise(resolve => setTimeout(resolve, 250));
							console.log("_mintConfig_ :" + this.state._mintConfig_);
							console.log("totalValue :" + this.state.totalValue);

						}

						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}

				} else if (web3Modal.cachedProvider == "walletconnect") {

					const chainId = 137;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});

						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Polygon Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://polygon-rpc.com/'],
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						publicSaleCostInUSD = await contract.methods.publicSaleCostInUSD().call();
						this.setState({ _publicSaleCostInUSD: publicSaleCostInUSD });

						public_sale_cost = await contract.methods.usdToEth(publicSaleCostInUSD).call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//

						mintAmount = 1;

						if (totalSupplyNFT == 20) {

							onlyLeft = 20 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							//valueOfNFTs = (mintAmount * this.state._cost);
							valueOfNFTs = Math.floor(mintAmount * this.state._cost);

							console.log("mintAmount :" + mintAmount);
							console.log("_cost :" + this.state._cost);

							this.setState({ nftMintingAmount: mintAmount });
							this.setState({ totalValue: valueOfNFTs });
							await new Promise(resolve => setTimeout(resolve, 250));

							let totalV = (this.state.totalValue / 1000000000000000000).toFixed(3);

							/*let _VarmintConfig_ = '{"type":"erc-721","totalPrice":' + fullPrice + ',"_mintAmount": "1" }'
							this.setState({ _mintConfig_: _VarmintConfig_ });*/
							await new Promise(resolve => setTimeout(resolve, 250));
							console.log("_mintConfig_ :" + this.state._mintConfig_);
							console.log("totalValue :" + this.state.totalValue);

						}

						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				} else if (web3Modal.cachedProvider == "coinbasewallet") {

					const chainId = 137;

					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Polygon Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://polygon-rpc.com/'],
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						publicSaleCostInUSD = await contract.methods.publicSaleCostInUSD().call();
						this.setState({ _publicSaleCostInUSD: publicSaleCostInUSD });

						public_sale_cost = await contract.methods.usdToEth(publicSaleCostInUSD).call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//

						mintAmount = 1;

						if (totalSupplyNFT == 20) {

							onlyLeft = 20 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							//valueOfNFTs = (mintAmount * this.state._cost);
							valueOfNFTs = Math.floor(mintAmount * this.state._cost);
							console.log("mintAmount :" + mintAmount);
							console.log("_cost :" + this.state._cost);
							this.setState({ nftMintingAmount: mintAmount });
							this.setState({ totalValue: valueOfNFTs });
							await new Promise(resolve => setTimeout(resolve, 250));

							let totalV = (this.state.totalValue / 1000000000000000000).toFixed(3);

							/*let _VarmintConfig_ = '{"type":"erc-721","totalPrice":' + fullPrice + ',"_mintAmount": "1" }'
							this.setState({ _mintConfig_: _VarmintConfig_ });*/
							await new Promise(resolve => setTimeout(resolve, 250));
							console.log("_mintConfig_ :" + this.state._mintConfig_);
							console.log("totalValue :" + this.state.totalValue);

						}

						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	videoPlay = async event => {
		event.preventDefault();

		this.setState({ _videoBtn: 1 });
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}



	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="page-loader">
						<div class="spinner"></div>
						<div class="txt">THE OUTLET LA BLACK CARD</div>
					</div>
					<div class="cont">
						<Element name="up">


							{this.state._navbarOpen < 1 ?
								(

									<div class="miniBarMain">
										<div class="logo2"><img src={logo} onClick={home} /></div>
										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form onSubmit={this.walletConnect} class="connect2">
													<button class="walletBtn">
														CONNECT WALLET
													</button>
												</form>) : (<form class="connect2" onSubmit={this.walletDisconnect}>
													<button class="walletBtn">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}
													</button>
												</form>)}

										</div>
										<div class="navbarMainImgSet"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
									</div>) :
								(<div class="miniBarMain">
									<div class="logo2"><img src={logo} onClick={home} /></div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect} class="connect2">
												<button class="walletBtn">
													CONNECT WALLET
												</button>
											</form>) : (<form class="connect2" onSubmit={this.walletDisconnect}>
												<button class="walletBtn">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}
												</button>
											</form>)}

									</div>
									<div class="navbarMainImgSet"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
								</div>)}

							<div class="headers">

								<div class="h1">
									<div class="logo2"><img src={logo} onClick={home} /></div>

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" onClick={home}>HOME</Link></div>
										<div ><Link activeClass="" id="fontSize" onClick={tab2}>OUTLET EVENT PLANING</Link></div>
										<div ><Link activeClass="" id="fontSize" onClick={tab3}>BLACK CARD EXCLUSIVE MEMBERSHIP</Link></div>
									</div>

									<div class="right">

										<div class="connect2">
											<img class="icons" onClick={opensea} src={os} />

											{this.state.walletAddress === '' ?
												(<form onSubmit={this.walletConnect} class="connect2">
													<button class="walletBtn">
														CONNECT WALLET
													</button>
												</form>) : (<form class="connect2" onSubmit={this.walletDisconnect}>
													<button class="walletBtn">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}
													</button>
												</form>)}

										</div>

									</div>


								</div>
							</div>

							{this.state._navbarOpen > 0 ?
								(<div class="littleNav" data-aos="fade-left">

									<div ><Link activeClass="" id="fontSize2" onClick={home}>HOME</Link></div>
									<div ><Link activeClass="" id="fontSize2" onClick={tab2}>OUTLET EVENT PLANING</Link></div>
									<div ><Link activeClass="" id="fontSize2" onClick={tab3}>BLACK CARD EXCLUSIVE MEMBERSHIP</Link></div>

									<div>
										<img class="osMobile" onClick={opensea} src={os2} />
									</div>
								</div>) : null}
						</Element>

						<div class="introduction">
							<div class="storyMain">THE OUTLET LA BLACK CARD <br /> NFT PROJECT</div>
							<div class="in2">
								<video class="video" src={video} autoPlay loop playsInline muted></video>
							</div>

						</div>

					</div>
					<Element name="story">
						<Story />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Story">

							<div class="storyH">MINT</div>

							<div class="mintDiv">
								<div class="pDiv"><video class="parrot" src={video} autoPlay loop playsInline muted></video>
								</div>
								<div class="mintCon">
									{this.state.walletAddress === '' ?
										(<div class="price">
											<div>Mint Price ${((this.state._publicSaleCostInUSD / 100) * mintAmount).toFixed(2)} <span class="maticP">(MATIC {(Number(this.state._price).toFixed(2))})</span></div>

										</div>) :
										(<div class="price">
											<div>Mint Price ${((this.state._publicSaleCostInUSD / 100) * mintAmount).toFixed(2)} <span class="maticP">(MATIC {(this.state.totalValue / 1000000000000000000).toFixed(2)})</span></div>

										</div>)}

									{this.state.totalSupply < 21 ?
										(<div>
											{this.state.walletAddress === '' ?

												(<div class="mintbuttondiv">
													<Link activeClass="" to="up"><button class="btnfos-0-3">Connect</button></Link>
												</div>) :
												(<div class="mintbuttondiv">
													{this.state._mintStatus === true ? (
														<div>

															<form onSubmit={this.onSubmit2}>
																<button class="btnfos-0-3" type="submit">
																	Mint Here</button>
															</form>

														</div>
													) : (<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3" type="submit">
															Mint Here</button>
													</form>)}
												</div>)}

											<div class="crossMint">
												<crossmint-pay-button
													clientId="2f8f716d-b303-481b-80ef-febaf341524b"
													mintConfig={this.state._mintConfig_}
												//environment="staging"
												/></div>
										</div>) :
										(
											<div class="mintbuttondiv">
												<button class="btnfos-0-3" disabled>Sold Out</button>
											</div>
										)}
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your NFTs</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">MINTING SUCCESSFUL</div>
												<div class="link"></div></div>)
											: null}

									</div>

								</div>

							</div>

						</div>

					</Element>

					<Element name="benefits">
						<Benefits />
					</Element>

					<div class="boxWrap2Img">
						<div class="images2"><img src={img1} /> <p></p> <img src={img2} /></div>
						<p></p>
						<div class="images2"><img src={img9} /> <p></p> <img src={img8} /></div>
					</div>
					<Element name="creator">
						<Creator />
					</Element>

					<Element name="extras">
						<Extras />
					</Element>

					<Video />

					<Element name="terms">
						<Terms />
					</Element>

					<div class="boxWrap2Img">
						<div class="images2"><img src={img3} /> <p></p> <img src={img5} /></div>
						<p></p>
						<div class="images2"><img src={img6} /> <p></p> <img src={img7} /></div>
					</div>

					<Element name="faq">
						<FAQ />
					</Element>
					<Footer />

				</div>

				<Link id="sticky-button" to="up" spy={true} smooth={true} duration={550}> <img class="stickyBtn2" src={up} /> </Link>

			</div >)
	}
}

export default App;

import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import instagram from '../assets/instagram.png';
import linkedIn from '../assets/linkedin.png';
import fb from '../assets/facebook.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const ig = () => {
	window.open("https://instagram.com/theoutletla?igshid=YmMyMTA2M2Y=");
}

const link = () => {
	window.open("https://www.linkedin.com/company/the-outlet-la/");
}

const facebook = () => {
	window.open("https://www.facebook.com/theoutletla?mibextid=LQQJ4d");
}


//<div class="devs"><a href="https://www.fiverr.com/appslkofficial" target="_blank">Meet the Devs Here</a> <img src={dev}/></div>

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">
                <div class="footer">
                    <div class="logoF">The Outlet LA</div>
                    <div class="socialCreator2">
                        <img onClick={ig} src={instagram} />
                        <img onClick={facebook} src={fb} />
                        <img onClick={link} src={linkedIn} />
                    </div>
                    <div class="copyright">Copyright © 2023 The Outlet LA. All Rights Reserved</div>
                    
                </div>

            </div>
        )
    }
}

export default Footer;


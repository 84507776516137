import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos'; 
import pic3 from '../assets/pic3.jfif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Extras extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">WHY YOU SHOULD GET PLUGGED</div>

                    <div class="about">

                    <img class="pic" src={pic3} />

                        <div class="aboutP">
                            <p>By obtaining the Black Card exclusive membership NFT, you will not only gain access to a range of exclusive member benefits, but you will also have governance over future events within the organization. This membership aligns you with The Outlet LA's vision to scale into one of the world's leading nightlife/hospitality groups. We warmly welcome you in becoming a valued member of our family.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Extras;


import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import pic1 from '../assets/pic1.jfif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">WHO WE ARE</div>

                    <div class="about">

                        <img class="pic" src={pic1} />

                        <div class="aboutP">
                            <p>The Outlet LA is a rapidly expanding black-owned event curation company that specializes in hosting sophisticated and upscale social events in Los Angeles. It is a destination where ambitious entrepreneurs and influential creatives convene to dress up, enjoy a vibrant atmosphere, and dance to great music.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Story;


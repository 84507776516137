import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import pic2 from '../assets/pic2.jfif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Terms extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">TERMS & CONDITIONS</div>

                    <div class="about2">

                        <div class="aboutP3">
                            <p>An NFT (non-fungible token) is a unique digital asset that is stored on a blockchain. The ownership and authenticity of an NFT is verified by the blockchain, making it a secure way to transfer ownership of digital assets, including event tickets. If an event NFT is revoked, it means that the ownership and access to the event may be taken away from the current owner. Here are some terms that may be associated with getting your event NFT revoked:</p>
                            <p class="line"></p>
                            <div>1. The Outlet LA reserves the right to revoke your NFT and terminate specific benefits associated with the card number. Furthermore, it cannot be resold once invalidated.</div>
                            <div>2. Violating the event rules: This could include but is not limited to actions such as bringing prohibited items into the event or disruptive behavior.</div>
                            <div>3.Fraudulent NFT - if the NFT was acquired through fraudulent means or the original seller did not have the rights to sell the ticket, it may be considered an invalid NFT, and the event organizers may revoke it.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Terms;


import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import pic2 from '../assets/creator.jfif';
import instagram from '../assets/instagram.png';
import linkedIn  from '../assets/linkedin.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const ig = () => {
	window.open("https://instagram.com/thedoctordapper?igshid=YmMyMTA2M2Y=");
}

const link = () => {
	window.open("https://www.linkedin.com/in/eric-jones-47b99b103/");
}

class Creator extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub">
                    <div class="storyH">THE CREATOR</div>

                    <div class="about">

                        <img class="pic" src={pic2} />

                        <div class="aboutP">
                            <p>Doctor Dapper, the Founder and CEO of The Outlet LA, has expressed a bullish outlook on the integration of Web3 and the Nightlife/Hospitality industries. His unwavering passion and exceptional foresight led him to assemble a team of industry experts to bring The Outlet LA’s debut NFT project to fruition. "The future is here, and one must either adapt or risk being left behind. I perceive opportunity in change and wholeheartedly embrace it."</p>
                           
                           <div class="socialCreator">
                            <img class="insta" onClick={ig} src={instagram}/>
                            <img class="insta" onClick={link} src={linkedIn}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Creator;


import React, { Component } from 'react';
 
class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="boxWrap2Story">

        <div class="accordin reveal">

        <div class="storyH">FAQ</div>

          <details>
            <summary>What can I do with my Black card exclusive membership NFT?</summary>
            <div class="faq__content">
              <p>You will not only gain access to a range of exclusive member benefits, but you will also have governance over future events within the organization. This membership aligns you with The Outlet LA's vision to scale into one of the world's leading nightlife/hospitality groups. Check out the benefits section to learn more.</p>
            </div>
          </details>
          <details>
            <summary>How many Black card exclusive membership NFT are there?</summary>
            <div class="faq__content">
              <p>With our mint, there will be 20 Black card exclusive membership available.</p>
             </div>
          </details>
          <details>
            <summary>What blockchain does The Outlet LA NFT utilize?</summary>
            <div class="faq__content">
              <p>The Outlet LA NFT collection is built on the Polygon Blockchain.</p>
             </div>
          </details>

          <details>
            <summary>Will my NFT appreciate in value?</summary>
            <div class="faq__content">
              <p>Yes, as The Outlet LA's community continues to grow, there will be a higher demand of individuals seeking access to our Black Card membership. Also, as our collection of assets and venues continues to grow, the resources that our Black Card members will have access to will continue to grow. By having more resources readily available to our Black Card members, people will be able to sell their NFT at a higher price point than they initially purchased it for.</p>
             </div>
          </details>

          <details>
            <summary>Can I resell the NFT?</summary>
            <div class="faq__content">
              <p>Yes, we will have secondary market on Opensea.</p>
             </div>
          </details>         

          <details>
            <summary>How do I mint/buy a NFT of The Outlet LA ?</summary>
            <div class="faq__content">
              <p>To purchase/mint your NFT, there are two methods you can use. Firstly, you can utilize Crossmint by providing your email address and debit/credit card information. Alternatively, you can add Matic, a cryptocurrency, to your wallet and use it for minting. Matic can be obtained from Coinbase and transferred to your Coinbase wallet. Both methods are effective for purchasing or minting your NFT.</p>
             </div>
          </details>  

          <details>
            <summary>Where can I see the NFT I've minted?</summary>
            <div class="faq__content">
              <p>You can view the NFT on the wallet that was utilized to mint it, including Coinbase wallet, Metamask, or Crossmint.</p>
             </div>
          </details>  

          <details>
            <summary>Is my NFT safe in my wallet?</summary>
            <div class="faq__content">
              <p>As long as your black card exclusive membership NFT remains in your wallet, it is completely secure.</p>
             </div>
          </details>  

          <details>
            <summary>When was the Genesis collection mint?</summary>
            <div class="faq__content">
              <p>Genesis mint was in Q2 2023.</p>
             </div>
          </details>  


          <details>
            <summary>What’s next?</summary>
            <div class="faq__content">
              <p>The outlet will continue to expand into many different areas within the Nightlife & Hospitality industry. We have so many amazing things in the works. We're trailblazing the adoption of NFT / Web3 in the Nightlife & Hospitality space, so you know we have other major projects in the works. Join our discord channel and follow us on Instagram to stay updated on what's new and fresh with The Outlet LA, this is only grazing the surface of what we have planned!</p>
             </div>
          </details>  


        </div>
      </div>


    )
  }
}

export default FAQ;

